import React, { Fragment } from 'react'
import { Button } from 'semantic-ui-react';

interface IProps {
    labelName: string;
    value: string | JSX.Element;
    copy?: boolean;
    link?: boolean;
}

export const DataFieldView : React.FC<IProps> = ({labelName, value, copy, link}) => {
    const idCopy = `id_${labelName.toLowerCase().replace(/ /g, '_')}`;
    const copyValue = () => {
        const input: HTMLInputElement | null = document.querySelector(`#${idCopy}`);
        input?.select();
        document.execCommand('copy');
    }
    if (!value) return null;
    return (
        <div>
            <label>{labelName}:</label>&nbsp;&nbsp;
            {
                (copy && <Fragment>
                    <input id={idCopy} readOnly value={value.toString()} />
                    <Button content="Copy" size="tiny" onClick={copyValue} />
                </Fragment>)
                ||
                (link && <a href={value.toString()} target="_blank" rel="noopener noreferrer">{value}</a>)
                || value
            }
        </div>
    )
}
