import { observable, action, configure } from "mobx";
import { RootStore } from "./rootStore";

configure({ enforceActions: "always" });

export default class LayoutStore {
  rootStore: RootStore;
  
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable loadingSpinner = false;

  public runWithSpinnerAsync = async (callBack: Function) => {
    this.setSpinner(true);
    var result = await callBack();
    this.setSpinner(false);
    return result;
  };

  @action private setSpinner = (show: boolean) => (this.loadingSpinner = show);
}

//  createContext(new LayoutStore());