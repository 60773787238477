import React, { useEffect, useState } from 'react'
import { Button, Header, List } from 'semantic-ui-react'
import agent from '../../app/api/agent';
import { ICourse } from '../../app/models/course';
import { ITeacherBasic } from '../../app/models/teacher';
import { TeacherSelector } from './TeacherSelector';

export interface ISelectableTeacher extends ITeacherBasic {
    deSelected?: boolean;
}

interface IProps {
    course: ICourse;
    onSubmit: (teachers: ITeacherBasic[]) => void;
}

export const SelectTeachersForm: React.FC<IProps> = ({ course, onSubmit }) => {

    const [teachers, setTeachers] = useState<ISelectableTeacher[]>([]);
    const [message, setMessage] = useState('Retrieving applicable teachers...');

    useEffect(() => {
        agent.Teachers.applicableForCourse(course.id)
        .then(t => {
            if (!t.length) {
                setMessage('No applicable teachers found for this course!');
            } else {
                setMessage('');
                setTeachers(t);
            }
        }, e => setMessage(e.data.errors));
    }, []);

    const handleSubmit = () => {
        onSubmit(teachers.filter(t => !t.deSelected));
    }

    const handleToggle = () => {
        setTeachers(t => [...t]);
    }

    const teacherSelector = (teacher: ITeacherBasic) => (
        <List.Item key={teacher.id}>
            <TeacherSelector teacher={teacher} language={course.language} onToggle={handleToggle}></TeacherSelector>
        </List.Item>
    );

    const teacherItems = () => {
        const excludes = course.teachers.map(t => t.id);
        return teachers.filter(t => !excludes.some(id => id === t.id)).map(teacherSelector);
    };

    const headerText = message
        ? message
        : `Select teachers to be emailed about ${course.name}`;

    return (
        <>
            <Header>{headerText}</Header>
            <List divided relaxed>
                {teacherItems()}
            </List>
            <Button primary onClick={handleSubmit} floated="right" disabled={teachers.filter(t => !t.deSelected).length === 0}>Select Teachers</Button>
        </>
    );
}