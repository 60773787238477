import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingComponent } from '../../app/layout/LoadingComponent';
import { Form as FinalForm, Field } from 'react-final-form';
import { TextInput } from '../../app/common/form/TextInput';
import { TextAreaInput } from '../../app/common/form/TextAreaInput';
import { combineValidators, isRequired, createValidator } from 'revalidate';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ReleasedSoldierVoucherFormModel } from '../../app/models/released-soldier-voucher';
import { useMetaTitle } from '../../app/layout/useMetaTitle';

const validate = combineValidators({
  soldierId: isRequired('Soldier Id'),
  crmDealId: isRequired('Deal ID'),
  amount: createValidator(
    message => value => {
      if (!(parseFloat(value) > 0)) {
        return message;
      }
    },

    () => 'Amount must be greater then 0'
  )('Amount')
})

interface DetailParams {
  id: string;
}

export const ReleasedSoldiersVoucherForm: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
  const { voucherStore } = useContext(RootStoreContext);
    const { loadingSpinner } = voucherStore;

    useMetaTitle((voucherStore.voucher?.id ? 'Edit ' + voucherStore.voucher?.id : 'Add') + ' Voucher');
  
    useEffect(() => {
      const id = parseInt(match.params.id);
      if (id) {
        voucherStore.loadIndividualVoucherAsync(id)
        .then(voucher => setVoucher(new ReleasedSoldierVoucherFormModel(voucher)));
      }

  }, [voucherStore, match.params.id]);

  const [voucher, setVoucher] = useState(new ReleasedSoldierVoucherFormModel());

  const handleSubmit = (values: any) => {
    values.amount = parseFloat(values.amount);
    voucherStore.submitVoucherAsync(values);
  }

  if (loadingSpinner) return <LoadingComponent />;

  return (
    <Segment clearing>
      <Header>{match.params.id ? 'Edit' : 'Add'} Voucher</Header>
      <hr></hr>
      <FinalForm
      validate={validate}
      initialValues={voucher}
        onSubmit={handleSubmit}
        render={({ handleSubmit, invalid, pristine }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <Field
              name="crmDealId"
              component={TextInput}
              placeholder="Pipedrive deal ID *"
              value={voucher.crmDealId || ""}
            />
            {!voucher.soldierId && <Field
              name="soldierId"
              placeholder="Soldier's National ID *"
              value={voucher.soldierId}
              component={TextInput}
            />}
            <Field
              name="description"
              component={TextAreaInput}
              rows={3}
              placeholder="Description"
              value={voucher.description}
            />
            <Field
              name="amount"
              type="number"
              component={TextInput}
              placeholder="Amount *"
              value={voucher.amount.toString() || ""}
            />
            <Button
              floated="right"
              color="blue"
              type="submit"
              content="Submit"
              disabled={invalid || pristine}
            />
            <Button
              floated="right"
              negative
              type="button"
              content="Cancel"
              onClick={() =>
                history.push(
                  history.location.pathname.replace(/\/(edit|add)/, '')
                )
              }
            />
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(ReleasedSoldiersVoucherForm);