import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Header, Menu, Segment, Table } from 'semantic-ui-react';
import agent from '../../app/api/agent';
import { LoadingComponent } from '../../app/layout/LoadingComponent';
import { useMetaTitle } from '../../app/layout/useMetaTitle';
import { ILanguage } from '../../app/models/language';
import { RootStoreContext } from '../../app/stores/rootStore';
import { displayErrors } from '../../app/utils/errors';
import { LanguageRow } from './LanguageRow';
import { Form as FinalForm, Field } from 'react-final-form';
import { TextInput } from '../../app/common/form/TextInput';
import { WysiwygInput } from '../../app/common/form/WysiwygInput';
import { ITestInvitationEmailTemplate } from '../../app/models/testInvitationEmailTemplate';
import { toast } from 'react-toastify';

export const Languages = () => {
	useMetaTitle('Languages');

	const [isLoading, setIsLoading] = useState(false);

	const [languages, setLanguages] = useState<ILanguage[]>([])
	useEffect(() => {
		setIsLoading(true);
		agent.Languages.list()
		.then(setLanguages)
		.catch(displayErrors)
		.finally(() => setIsLoading(false));
	}, []);


	const [crmOptions, setCrmOptions] = useState<{ value?: number, text: string, key: React.Key }[]>([]);
	useEffect(() => {
		agent.Languages.getCrmValues()
		.then(options => {
			setCrmOptions(
				[{ text: '', value: undefined }, ...options]
				.map(({ text, value }, i) => ({ text, value, key: i }))
				.sort((a, b) => a.text.localeCompare(b.text))
			);
		})
		.catch(displayErrors)
	}, []);

	const updateLanguage = (language: ILanguage) => {
		setLanguages([...languages.filter(lang => lang.id !== language.id), language]);
	};

	const { modalStore } = useContext(RootStoreContext);

	const editInvitationTemplate = async () => {
		const handleSubmit = (values: ITestInvitationEmailTemplate) => {
			setIsLoading(true);
			agent.Languages.updateEmailTemplate(values)
			.catch(displayErrors)
			.finally(() => {
				modalStore.closeModal()
				setIsLoading(false);
			});
		}

		const emailTemplate = await agent.Languages.getEmailTemplate()
		.catch(errors => {
			modalStore.closeModal()
			setIsLoading(false);
			displayErrors(errors, 'Unable to load email template.');
			modalStore.closeModal();
		});

		const copyPlaceholder: React.MouseEventHandler<HTMLElement> = e => {
			navigator.clipboard.writeText(e.currentTarget.innerText);
			toast.success('Copied!', { autoClose: 2500 });
		}

		
		emailTemplate && modalStore.openModal((
			<FinalForm
				onSubmit={handleSubmit}
				// validate={validate}
				render={({ handleSubmit, submitting, form, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
					<Form onSubmit={handleSubmit} error>
						<Header as='h4' content="Edit Invitation Template"/>
						<Field name='subject' component={TextInput} initialValue={emailTemplate.subject}/>
						{/* {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text="Couldn't find specified lesson" />} */}
						<Field name="body" component={WysiwygInput} height="75vh" initialValue={emailTemplate.body} />
						{emailTemplate.placeholders.map((placeholder, i) => (
							<code key={i} style={{ marginInlineStart: '1rem', cursor: 'pointer' }} onClick={copyPlaceholder}>{placeholder}</code>
						))}
						<Button floated="right" color='instagram' content='Update' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} />
						<Button floated="right" color='youtube' content='Cancel' onClick={modalStore.closeModal} />
					</Form>
				)}
			/>
		), 'large');
	};

	if (isLoading) return <LoadingComponent />;

	return (
		<>
			<Menu compact>
				<Menu.Item onClick={editInvitationTemplate}>Edit Test Invitation Template</Menu.Item>
			</Menu>
			<Segment>
				<Header>Languages</Header>
				<Table basic="very">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Language Name<small> (From TC)</small></Table.HeaderCell>
							<Table.HeaderCell>Is Available</Table.HeaderCell>
							<Table.HeaderCell>Sends Level Test invitations</Table.HeaderCell>
							<Table.HeaderCell>Pipedrive</Table.HeaderCell>
							<Table.HeaderCell>Hebrew Name</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{languages.sort((a, b) => a.name.localeCompare(b.name)).map(language => (
							<LanguageRow key={language.id} language={language} crmOptions={crmOptions} onUpdateLanguage={updateLanguage} />
						))}
					</Table.Body>
				</Table>
			</Segment>
		</>
	);
};