import React, { useState } from 'react';
import { Form, Button, Header } from 'semantic-ui-react';
import { TextInput } from '../../app/common/form/TextInput';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired, matchesPattern } from 'revalidate';
import agent from '../../app/api/agent';
import { CourseStatus, ICourse } from '../../app/models/course';

interface IProps {
    setCourse: (course: ICourse) => void;
}

const pattern = /^(?:(?:https\:\/\/)?secure\.tutorcruncher\.com\/cal\/service\/(\d{6,})\/?$)|^(\d{6,})$/;

const validate = combineValidators({
    course: composeValidators(
        isRequired('Course ID or URL'),
        matchesPattern(pattern)
        ({message: 'Must be a valid Job ID or Job URL'})
    )()
});

const validateId = (val: string) => {
    const match = val.match(pattern);
    if (!match) return NaN;
    return parseInt(match[1] || match[2]);
}

export const GetCourseForm: React.FC<IProps> = ({ setCourse }) => {

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const getCourse = async (val: {course: string}) => {
        const id = validateId(val.course);
        if (id) {
            setMessage('Getting Data...');
            setLoading(true);
            try {
                const course = await agent.Courses.get(id);
                console.log(course.courseStatus, CourseStatus.Available, course.courseStatus === CourseStatus.Available);
                if (course.courseStatus === CourseStatus.Available) {
                    //setMessage('');
                    setCourse(course);
                } else {
                    setMessage(`${course.name} is not set to Available for Application status.`);
                }
            } catch (error) {
                setMessage(error.data.errors.course);
            }
            setLoading(false);
        } else {
            setMessage('Not a valid Job ID or Job URL');
        }
    };

    return (
        <>
            <Header>Select Course</Header>
            <FinalForm
                validate={validate}
                onSubmit={getCourse}
                render={({ handleSubmit, invalid, pristine }) => (
                    <Form onSubmit={handleSubmit} error>
                        <Form.Group widths="equal">
                            <Field
                                // value={values.course}
                                name="course"
                                component={TextInput}
                                label="Enter Course ID or URL:"
                                placeholder="https://secure.tutorcruncher.com/cal/service/??????/"
                                disabled={loading}
                            />
                        </Form.Group>
                        <Button
                            floated="right"
                            color="blue"
                            type="submit"
                            content="Get Course"
                            disabled={invalid || pristine || loading} />
                        {message}
                    </Form>
                )}
            />
        </>
    )
}