export interface IReleasedSoldierVoucherViewModel {
    id: number;
    amount: number;
    description: string;
    referenceNumber: string;
    voucherDate: Date;
    isRedeemed: boolean;
    crmDealId: string;
    courseName: string;
    clientName: string;
    soldierId: string;
}

/*export class ReleasedSoldierVoucherViewModel implements IReleasedSoldierVoucherViewModel {
    id: number;
    amount: number;
    description: string;
    referenceNumber: string;
    voucherDate: Date;
    isRedeemed: boolean;
    
    constructor(amount: number, description: string) {
        this.amount = amount;
        this.description = description;
        this.voucherDate = new Da
    }
}*/

export class ReleasedSoldierVoucherFormModel {
    soldierId: string = '';
    amount: number = NaN;
    description: string = '';
    crmDealId: string = '';

    constructor(init?: IReleasedSoldierVoucherViewModel) {
        Object.assign(this, init);
    }
}

export interface IReleasedSoldierVoucherFormValues extends IReleasedSoldierVoucherViewModel {

    //voucherDateTemp?: Date
}
/*
export class ReleasedSoldierVoucherFormValues implements IReleasedSoldierVoucherFormValues {
    id = NaN;
    amount = 0;
    referenceNumber = '';
    description = '';
    soldierId = '';
    voucherDate: Date = new Date();
    //voucherDateTemp?: Date = undefined;
    status = VoucherStatus.Pending;

    constructor(init?: IReleasedSoldierVoucherViewModel) {
        if (init && init.voucherDate && typeof init.voucherDate === 'string'){
            init.voucherDate = new Date(init.voucherDate);
        }
        Object.assign(this, init);
    }
}*/