import React from 'react'
import { Accordion, Container, Image, Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export const SideBar = () => (
         <Container>
           <a href="/">
             <Image src="/assets/logo.png" alt="logo" className="logo" />
           </a>
           <Menu as={Accordion} vertical secondary>
             <Menu.Item as={NavLink} to="/" exact>
               Dashboard
             </Menu.Item>
             <Menu.Item as={NavLink} to="/vouchers">
               Vouchers
             </Menu.Item>
             <Menu.Item as={NavLink} to="/forms/sales/conversion">
               Conversion Form
             </Menu.Item>
             <Menu.Item as={NavLink} to="/forms/open-job-emails">
               Open Job Emails
             </Menu.Item>
             <Menu.Item as={NavLink} to="/languages">
               Languages
             </Menu.Item>
           </Menu>
         </Container>
       );