import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

export const LoadingComponent = () => {
  return (
    <Dimmer page active inverted>
      <Loader />
    </Dimmer>
  );
};
