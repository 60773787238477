import { toast } from "react-toastify";

interface IError {
	message?: string;
	data?: {
		errors?: string | string[];
		title?: string;
	}
}

export const displayErrors = (error: IError, message = '') => {
	message && toast.error(message);
	const { data } = error;
	if (data?.errors && Object.keys(data.errors).length > 0) {
		const { errors } = data;
		toast.error(typeof errors === 'string' ? errors : Object.entries(errors).map(([k, v]) => `${k}: ${v}`).join('\n'));
	} else {
		data?.title && toast.error(data.title);
	}
};

export const createDisplayErrors = (message: string) => {
	toast.error(message);
	// error.data?.errors && Object.keys(error.data.errors).length > 0
	// && toast.error(Object.values(error.data.errors).flat().join('\n'));
	return displayErrors;
};