import React, { Fragment, useContext, useEffect } from 'react';
import { Menu, Segment, Button, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, Link } from 'react-router-dom';
import { LoadingComponent } from '../../app/layout/LoadingComponent';
import { FullDateFormat, formatCurrency } from '../../app/common/formats/format';
import { format } from 'date-fns';
import { RootStoreContext } from '../../app/stores/rootStore';
import { DataFieldView } from '../../app/common/components/DataFieldView';
import { useMetaTitle } from '../../app/layout/useMetaTitle';

interface DetailParams {
    id: string;
}

export const ReleasedSoldierVoucherDetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
  const voucherStore = useContext(RootStoreContext).voucherStore;
    const { voucher } = voucherStore;

    useMetaTitle('Voucher ' + voucher?.id);
  
    useEffect(() => {
        const id = parseInt(match.params.id);
        if(id > 0) {
            voucherStore.loadIndividualVoucherAsync(id);
        } else {
            history.push('/not-found');
        }
    }, [voucherStore, match.params.id, history]);

    if (voucherStore.loadingSpinner) return <LoadingComponent />;
    
  return (
      voucher && (<Fragment>
      <Menu compact>
        <Menu.Item as={Link} to='/vouchers'>Back</Menu.Item>
      </Menu>
      {!voucher.referenceNumber &&
      <Fragment>
        <Menu compact>
          <Menu.Item as={Link} to={`/vouchers/${voucher.id}/edit`}>Edit</Menu.Item>
          <Menu.Item as={Link} to={`/vouchers/${voucher.id}/delete`}>Delete</Menu.Item>
        </Menu>
        <Menu compact>
          <Menu.Item as={Button} onClick={voucherStore.submitVoucherForPaymentAsync} color='red' >Submit for Payment</Menu.Item>
        </Menu>
      </Fragment>
      }
      <Segment clearing>
          <Header>{voucher.clientName && voucher.clientName + "'s "}Voucher</Header>
          <hr></hr>
          <DataFieldView labelName='Amount' value={formatCurrency(voucher.amount)} />
          <DataFieldView labelName='Client Name' value={voucher.clientName} />
          {voucher.crmDealId && <DataFieldView 
            labelName='Course'
            value={
              <a target='_blank' href={'https://lingo.pipedrive.com/deal/' + voucher.crmDealId} >
                {voucher.courseName || 'View in Pipedrive'}
              </a>
            }
          />}
          {voucher.referenceNumber && 
            <Fragment>
              <DataFieldView labelName='External Reference Number' value={voucher.referenceNumber} />
              <DataFieldView labelName='Issuing Date' value={voucher.voucherDate.getTime() > 0 && format(voucher.voucherDate, FullDateFormat) || ''} />
            </Fragment>
          }
          {voucher.description && <DataFieldView labelName='Description' value={voucher.description} />}
      </Segment>
      </Fragment>)
  );
};

export default observer(ReleasedSoldierVoucherDetails);