import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import { TextInput } from '../../app/common/form/TextInput';
import { combineValidators, composeValidators, isRequired, matchesPattern } from 'revalidate';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';
import agent from '../../app/api/agent';
import ConversionDetails from '../../app/models/conversion';

const pattern = /([0-9]+)\/?$/;
const validate = combineValidators({
  deal: composeValidators(
    isRequired('Deal ID or URL'),
    matchesPattern(pattern)({message: 'Must be a valid Deal ID or Deal URL'})
  )()
});

const validateId = (val: string) => {
  const match = val.match(pattern);
  if (!match) return NaN;
  return parseInt(match[1]);
}

interface Props {
  onImport: (conversion: ConversionDetails) => void,
  onCancel: () => void
}

export const ImportForm = ({ onImport, onCancel }: Props) => {
  const handleSubmit = async (values: any) => {
    const id = validateId(values.deal);
    if (id) {
      try {
        const deal = await agent.Forms.getConversion(id);
        onImport(deal);
      } catch (error) {
        
      }
    }
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      validate={validate}
      render={({handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit}) => (
        <Form onSubmit={handleSubmit} error>
          <Header as='h2' content="Enter Deal ID or URL" color='blue' textAlign='center' />
          <Field name='deal' component={TextInput}/>
          {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text='Invalid Deal ID or URL' />}
          <Button color='youtube' content='Cancel' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} onClick={onCancel} />
          <Button color='instagram' content='Import' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} />
        </Form>
      )}
    />
  )
}