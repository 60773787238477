import React from 'react'
import { Segment, Header, Icon, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Segment>
      <Header icon>
        <Icon name='search' />
        Oops - we've looked everywhere but couldn't find this.
        </Header>
        <Segment.Inline>
          <Button as={Link} to='/vouchers' primary>
            Return to Vouchers page
          </Button>
        </Segment.Inline>
    </Segment>
  )
}

export default NotFound;