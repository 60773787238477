import React, { useContext } from "react";
import { Menu, Input, Dropdown } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

const HeaderBar = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, logout } = rootStore.userStore;
  
  return (
    <Menu fixed="top" secondary className="nav-bar-blue" widths={3}>
      <Menu.Item header position="left" className="brand" id="brand">
        LingoLearn Manager
      </Menu.Item>
      <Menu.Item>
        <Input icon="search" placeholder="Search..." />
      </Menu.Item>
      {
        user && <Menu.Item position="right" name="logout" id="top-right">
          {/* <Image avatar spaced='right' content={user.displayName.split(" ").map((n)=>n[0].toUpperCase()).join('')} /> */}
          <Dropdown pointing='top left' text={user.displayName} direction='left'>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/profile/username`} text='My Profile' icon='user' />
              <Dropdown.Item onClick={logout} text='Logout' icon='power' />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      }
    </Menu>
  );
};

export default observer(HeaderBar);