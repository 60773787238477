import{ Editor }from '@tinymce/tinymce-react'
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {}

const init = {
	plugins: ['advlist lists link charmap preview searchreplace visualblocks code table paste directionality'],
	toolbar: [
		'undo redo | formatselect fontselect fontsizeselect | removeformat | pastetext',
		'bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify ltr rtl | bullist numlist outdent indent'
	]
};

export const WysiwygInput: React.FC<IProps> = ({label, input, width, height, type, meta: {touched, error}}) => (
	<Form.Field error={touched && !!error} type={type} width={width}>
		<label htmlFor={input.name}>{label}</label>
		<Editor tinymceScriptSrc="/assets/tinymce/tinymce.min.js" init={{ ...init, height }} value={input.value} id={input.name} onEditorChange={input.onChange} />
		{touched && error && <Label basic color='red'>{error}</Label>}
	</Form.Field>
);