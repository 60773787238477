import { format } from "date-fns";

export const FullDateFormat = 'dd/MM/yyyy HH:mm';
export const DateFormat = 'dd/MM/yyyy';
export const FullTimeFormat = 'HH:mm';

export const formatDate = (date: Date) => format(date.getTime(), FullDateFormat);
export const formatTime = (date: Date) => format(date.getTime(), FullTimeFormat);
export const formatCurrency = (amount: number) => `${amount} ₪`;
export const formatDuration = (duration: number) => {
    const m = duration % 60;
    const h = (duration - m) / 60;
    const stringify = (num: number, term: string) => num > 0 ? `${num} ${term}${num > 1 ? 's' : ''}` : '';
    return `${stringify(h, 'hour')} ${stringify(m, 'minute')}`;
}