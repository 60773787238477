import React, { useState } from 'react'
import { Segment } from 'semantic-ui-react'
import { ICourse } from '../../app/models/course';
import { GetCourseForm } from './GetCourseForm';
import { ITeacherBasic } from '../../app/models/teacher';
import { SelectTeachersForm } from './SelectTeachersForm';
import { useMetaTitle } from '../../app/layout/useMetaTitle';

export const OpenJobEmails = () => {
    enum Stage {
        GetCourse,
        SelectTeachers,
        EditMail
    }

    const [course, setCourse] = useState<ICourse>();
    const [stage, setStage] = useState(Stage.GetCourse);
    const [teachers, setTeachers] = useState<ITeacherBasic[]>([]);
    useMetaTitle((() => { 
        switch (stage) {
            case Stage.GetCourse: return 'Select Open Course';
            case Stage.SelectTeachers: return 'Select Recipients';
            case Stage.EditMail: return 'Edit Email';
        }
     })());
          
    const handleSetCourse = async (c: ICourse) => {
        setCourse(c);
        setStage(Stage.SelectTeachers);
    }

    const handleSelectTeachers = (teachers: ITeacherBasic[]) => {
        setTeachers(teachers);
        setStage(Stage.EditMail);
    }

    return (
        <Segment clearing style={{"maxWidth": "80%"}}>
            {stage === Stage.GetCourse && <GetCourseForm setCourse={handleSetCourse}/>}
            {stage === Stage.SelectTeachers && course && <SelectTeachersForm course={course} onSubmit={handleSelectTeachers}></SelectTeachersForm>}
            {stage === Stage.EditMail && teachers.map(t => t.firstName + ' ' + t.lastName)}
        </Segment>
    )
}
