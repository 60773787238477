import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label, Select } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<any, HTMLElement>, FormFieldProps {}

export const SelectInput: React.FC<IProps> = ({label, input, width, options, placeholder, meta: {touched, error}}) => {
  input.id = input.name;
  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <Select
        value={input.value}
        onChange={(_e, data) => {input.onChange(data.value)}}
        placeholder={placeholder}
        options={options}
      />
      {touched && error &&(
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  )
}