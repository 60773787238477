import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {}

export const TextInput: React.FC<IProps> = ({label, input, width, type, placeholder, onInput, meta: {touched, error}}) => {
  return (
    <Form.Field error={touched && !!error} type={type} width={width}>
      <label htmlFor={input.name}>{label}</label>
      <input {...input} placeholder={placeholder} id={input.name} onInput={onInput} />
      {touched && error &&(
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  )
}