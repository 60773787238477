import { useEffect } from 'react';

export const useMetaTitle = (title?: string) => {
    useEffect(() => {
        const prevTitle = document.title;
        const newTitle = `${title ? `${title} | ` : ''}LingoLinx`;
        document.title = newTitle;
        return () => {
          document.title = prevTitle;
        };
      }, [title]);
    }