import axios, { AxiosResponse } from 'axios';
import { IReleasedSoldierVoucherViewModel, ReleasedSoldierVoucherFormModel } from '../models/released-soldier-voucher';
import { history } from '../..'
import { toast } from 'react-toastify';
import { IUser, IUserFormValues } from '../models/user';
import { format } from 'date-fns';
import { ICourse } from '../models/course';
import { ITeacher, ITeacherBasic } from '../models/teacher';
import ConversionDetails, { ConversionValues } from '../models/conversion';
import { ILanguage } from '../models/language';
import { ITestInvitationEmailTemplate } from '../models/testInvitationEmailTemplate';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt');
    if (token) config.headers.Authorization = 'Bearer ' + token;
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(undefined, error => {
    if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error.');
    }
    const { status, headers } = error.response;
    if (status === 401 && headers['www-authenticate'].search('Bearer error="invalid_token", error_description="The token expired at') > -1) {
        window.localStorage.removeItem('jwt');
        history.push('/');
        toast.info('Your session has expired, please log in again');
    }
    // if (status === 404) {
    //     history.push('/not-found');
    // }
    if(status === 500) toast.error('Server error - check the terminal for more info!');

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

// const sleep = (ms = 1000) => (response: AxiosResponse) =>
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms))

const requests = {
    get: (url: string) => axios.get(url)
    // .then(sleep())
    .then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body)
    // .then(sleep())
    .then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body)
    // .then(sleep())
    .then(responseBody),
    delete: (url: string) => axios.delete(url)
    // .then(sleep())
    .then(responseBody),
}

const Vouchers = {
    buildEndoint: (id: string | number = '') => '/ReleasedSoldierVouchers' + (id ? `/${id}` : ''),
    list: function(): Promise<IReleasedSoldierVoucherViewModel[]> {return requests.get(this.buildEndoint())},
    details: function(id: number): Promise<IReleasedSoldierVoucherViewModel> {return requests.get(this.buildEndoint(id))},
    create: function(voucher: ReleasedSoldierVoucherFormModel) {return requests.post(this.buildEndoint(), voucher)},
    update: function(id: number, voucher: ReleasedSoldierVoucherFormModel) {return requests.put(this.buildEndoint(id), voucher)},
    delete: function(id: number) {return requests.delete(this.buildEndoint(id))},
    submitForPaymentAsync: function(id: number) {return requests.post(this.buildEndoint(id) + '/SubmitForPayment', {})}
}

const User = {
    buildEndoint: (id: string | number = '') => '/user' + (id ? `/${id}` : ''),
    current: function(): Promise<IUser> {return requests.get(this.buildEndoint())},
    login: function(user: IUserFormValues): Promise<IUser> {return requests.post(this.buildEndoint('login'), user)},
    register: function(user: IUserFormValues): Promise<IUser> {return requests.post(this.buildEndoint('register'), user)}
}

const Courses = {
    get: (id: number): Promise<ICourse> => requests.get(`/courses/${id}`),
    updateMeetingNumber: (courseId: number, meetingNumber: number) => requests.put(`/courses/${courseId}?meetingnumber=${meetingNumber}`, {})
}

const Forms = {
	submit: async (values: ConversionValues) => {
		const getBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			reader.readAsDataURL(blob);
		});
		const body = {
			...values,
			invoice: values.invoice && await getBase64(values.invoice)
		};
		return requests.post('/internalforms/conversion', body);
	},
    getConversion: (dealId: number): Promise<ConversionDetails> => requests.get(`/internalforms/conversions/${dealId}`),
    getConversionFormParamsAsync: () => requests.get('/internalforms/conversions/params')
}

const Teachers = {
    applicableForCourse: (courseId: number): Promise<ITeacherBasic[]> => requests.get(`/teachers/for-course/${courseId}`),
    getTeacher: (id: number): Promise<ITeacher> => requests.get(`teachers/${id}`)
}

const Languages = {
	list: (): Promise<ILanguage[]> => requests.get('languages'),
	update: (language: ILanguage): Promise<void> => requests.put(`languages/${language.id}`, language),
	getCrmValues: (): Promise<{ text: string, value: number }[]> => requests.get('languages/crmValues'),
	getEmailTemplate: (): Promise<ITestInvitationEmailTemplate> => requests.get('languages/emailTemplate'),
	updateEmailTemplate: (values: ITestInvitationEmailTemplate): Promise<void> => requests.put('languages/emailTemplate', values)
};

export default {
    Vouchers,
    User,
    Forms,
    Courses,
    Teachers,
	Languages
}

function urlDateFormat(date: Date) {
    return format(date, 'yyyy-MM-dd');
}
