import React from 'react';
import { Route, useLocation, Switch} from 'react-router-dom';
import { HomePage } from '../../features/home/HomePage';
import ReleasedSoldierVoucherDetails from '../../features/released-soldiers/ReleasedSoldierVoucherDetails';
import ReleasedSoldiersVouchersTable from '../../features/released-soldiers/ReleasedSoldiersVouchersTable';
import ReleasedSoldiersVoucherForm from '../../features/released-soldiers/ReleasedSoldiersVoucherForm';
import NotFound from './NotFound';
import { LoginForm } from '../../features/user/LoginForm';
import PrivateRoute from './PrivateRoute';
import { ConversionForm } from '../../features/InternalForms/ConversionForm';
import { OpenJobEmails } from '../../features/OpenJobEmails/OpenJobEmails';
import { Languages } from '../../features/Languages/Languages';

export default function Routs() {
    return <Switch>
                <Route exact path='/login' component={LoginForm} />
                <Route exact path='/' component={HomePage} />
                <PrivateRoute exact path='/vouchers' component={ReleasedSoldiersVouchersTable} />
                <PrivateRoute key={useLocation().key} exact path={['/vouchers/add', '/vouchers/:id/edit']} component={ReleasedSoldiersVoucherForm} />
                <PrivateRoute exact path='/vouchers/:id' component={ReleasedSoldierVoucherDetails} />

                <PrivateRoute exact path='/forms/sales/conversion' component={ConversionForm} />
                <PrivateRoute exact path='/forms/open-job-emails' component={OpenJobEmails} />

                <PrivateRoute exact path='/languages' component={Languages} />

                <Route component={NotFound} />
            </Switch>
}
