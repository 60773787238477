import { Table, Segment, Menu, Header } from "semantic-ui-react";
import React, { useContext, Fragment, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingComponent } from "../../app/layout/LoadingComponent";
import { Link } from "react-router-dom";
import { formatDate, formatCurrency } from "../../app/common/formats/format";
import { RootStoreContext } from "../../app/stores/rootStore";
import { useMetaTitle } from "../../app/layout/useMetaTitle";

export const ReleasedSoldiersVouchersTable = () => {
  const voucherStore = useContext(RootStoreContext).voucherStore;

  useMetaTitle('Vouchers');

  useEffect(() => {
    voucherStore.loadVouchers();
  }, [voucherStore]);

  if (voucherStore.loadingSpinner) return <LoadingComponent />;

  return (
    <Fragment>
      <Menu compact>
        <Menu.Item as={Link} to='/vouchers/add'>
          Add
        </Menu.Item>
      </Menu>
      <Segment>
        <Header>Vouchers</Header>
        <hr></hr>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.Cell>Voucher ID</Table.Cell>
              <Table.Cell>Target Course</Table.Cell>
              <Table.Cell>Amount</Table.Cell>
              <Table.Cell>Issuing Date</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {voucherStore.getVoucherArray().map(voucher => (
              <Table.Row key={voucher.id}>
                <Table.Cell>
                  <Link to={'/vouchers/' + voucher.id}>
                    Voucher {voucher.id}
                  </Link>
                </Table.Cell>
                <Table.Cell>{voucher.courseName}</Table.Cell>
                <Table.Cell>{formatCurrency(voucher.amount)}</Table.Cell>
                <Table.Cell>{voucher.voucherDate.getTime() > 0 ? formatDate(voucher.voucherDate) : "Not submitted"}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </Fragment>
  );
};

export default observer(ReleasedSoldiersVouchersTable);
