import React, { useContext, useEffect, useState } from 'react'
import { Form as FinalForm, Field } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { combineValidators, composeValidators, isAlphabetic, isRequired } from 'revalidate';
import { Form, Button, Popup, Grid, GridColumn, Icon, Step } from 'semantic-ui-react';
import agent from '../../app/api/agent';
import FileUpload from '../../app/common/components/FileUpload';
import { SelectInput } from '../../app/common/form/SelectInput';
import { TextAreaInput } from '../../app/common/form/TextAreaInput';
import { TextInput } from '../../app/common/form/TextInput';
import { useMetaTitle } from '../../app/layout/useMetaTitle';
import ConversionDetails, { ConversionValues } from '../../app/models/conversion';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ImportForm } from './ImportForm';

const INITIAL_FORM_PARAMS = {
    sex: [],
    category: [],
    language: [],
    level: [],
    rep: [],
    currency: []
};

const selectOptions = (options: string[], skipSplit = false) => {
    return options.map(v => ({ text: skipSplit ? v : v.split(/(?<![-|\(])(?=[A-Z])/).join(' '), value: v }));
};

enum ConversionSteps {
    ClientDetails,
    StudentDetails,
    CourseDetails,
    PaymentDetails,
    InternalNotes,
    ClientNotes
}

export const ConversionForm: React.FC<RouteComponentProps> = ({history}) => {
    useMetaTitle('Add Conversion');

    const [step, setStep] = useState(ConversionSteps.ClientDetails);

    const [conversionValues, setConversionValues] = useState<ConversionValues>({});
    const validate = (() => {
        const reqAlpha = (name: string) => composeValidators(
            isRequired(name),
            isAlphabetic(name)
        )();
        // const inStep = (reqStep: ConversionSteps) => 
        return combineValidators({
            firstName: reqAlpha('First Name'),
            lastName: reqAlpha('Last Name'),
            email: isRequired('Email'),
            country: isRequired('Country and time zone')
        });
    })();

    const [formParams, setFormParams] = useState(INITIAL_FORM_PARAMS);

    useEffect(() => {
        agent.Forms.getConversionFormParamsAsync()
        .then(setFormParams)
        .catch(error => {
            toast.error("Failed to retrieve form parameters.");
            error.data && Object.keys(error.data.errors).length > 0
            && toast.error(Object.values(error.data.errors).flat().join('\n'));
        });
    }, []);

    const handleSubmit = (values: any) => {
        // console.log(values);
        agent.Forms.submit(values)
        .then(console.log)
        // .then(() => history.push('/'))
        .catch(alert);
    }

    const { modalStore } = useContext(RootStoreContext);

    const handleImport = ({ client, deal }: ConversionDetails) => {
        setConversionValues(values => ({
            ...values,
            age: client.age.toString(),
            assignment: deal.suitableTeacher,
            availabilty: deal.schedulingPreferences,
            category: deal.dealType,
            country: client.country,
            language: deal.language,
            currency: deal.currency,
            email: client.email,
            email2: client.secondEmail ?? values.email2,
            firstName: client.firstName,
            focus: deal.focusOn,
            lastName: client.lastName,
            leadComments: deal.offerTerms,
            level: formParams.level.find((l: string) => l.startsWith(deal.currentLevel)) || values.level,
            owner: deal.owner,
            phone: client.phone,
            phone2: client.secondPhone ?? values.phone2,
            price: deal.value.toString(),
            quantity: deal.packageHours.toString(),
            rep: deal.owner,
            serviceComments: deal.notes,
            sex: client.sex,
            skype: client.skype ?? values.skype,
            teacherLang: deal.needsHebrewSpeaker ? "Yes" : "No",
        }));
        modalStore.closeModal();
    }

    const importConversion = () => {
        modalStore.openModal(<ImportForm onCancel={modalStore.closeModal} onImport={handleImport}/>);
    }

    const [invoiceSrc, setInvoiceSrc] = useState('');

    const setInvoice = (files: File[]) => {
        if (!files.length) return;

        setConversionValues({
            ...conversionValues,
            invoice: files[0]
        });
        setInvoiceSrc(URL.createObjectURL(files[0]))
    }

    useEffect(() => () => {
        URL.revokeObjectURL(invoiceSrc);
    });

    const renderSteps = () => {
        const renderStep = (stepId: ConversionSteps, title: string) =>
        <Step completed={step > stepId} active={step === stepId} disabled={step < stepId} >
            <Step.Content>
                <Step.Title>{title}</Step.Title>
            </Step.Content>
        </Step>;

        return <Step.Group ordered widths={6}>
            {renderStep(ConversionSteps.ClientDetails, 'Client Details')}
            {renderStep(ConversionSteps.StudentDetails, 'Student Details')}
            {renderStep(ConversionSteps.CourseDetails, 'Course Details')}
            {renderStep(ConversionSteps.PaymentDetails, 'Payment Details')}
            {renderStep(ConversionSteps.InternalNotes, 'Internal Notes')}
            {renderStep(ConversionSteps.ClientNotes, 'Client Notes')}
        </Step.Group>;
    }

    const copyFromClient = () => {
        setConversionValues(values => ({
            ...values,
            ageStudent: values.age,
            countryStudent: values.country,
            emailStudent: values.email,
            email2Student: values.email2,
            firstNameStudent: values.firstName,
            lastNameStudent: values.lastName,
            motherTStudent: values.motherT,
            phoneStudent: values.phone,
            phone2Student: values.phone2,
            sexStudent: values.sex,
            skypeStudent: values.skype
        }));
    }

    const changeStep = (newStep: ConversionSteps, values: ConversionValues) => {
        setConversionValues(values);
        setStep(newStep);
    }

    const moveNext = (values: ConversionValues) => step < ConversionSteps.ClientNotes && changeStep(step + 1, values);
    const movePrev = (values: ConversionValues) => step > ConversionSteps.ClientDetails && changeStep(step - 1, values);

    return (
        <Grid>
            <GridColumn width={13}>
                {renderSteps()}
                <FinalForm
                    validate={validate}
                    initialValues={conversionValues}
                    onSubmit={handleSubmit}
                    render={({ handleSubmit, invalid, values }) => (
                        <Form onSubmit={handleSubmit}>
                            {step === ConversionSteps.ClientDetails && <>
                                <Button onClick={importConversion} style={{ marginBottom: '1rem' }}>Import</Button>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.firstName}
                                        name="firstName"
                                        component={TextInput}
                                        placeholder="English please"
                                        label="First Name*"
                                    />
                                    <Field
                                        value={conversionValues.lastName}
                                        name="lastName"
                                        component={TextInput}
                                        placeholder="English please"
                                        label="Last Name*"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.email}
                                        name="email"
                                        component={TextInput}
                                        label="Lead Email*"
                                    />
                                    <Field
                                        value={conversionValues.email2}
                                        name="email2"
                                        component={TextInput}
                                        label="Lead Email 2"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.phone}
                                        name="phone"
                                        component={TextInput}
                                        label="Phone number*"
                                    />
                                    <Field
                                        value={conversionValues.phone2}
                                        name="phone2"
                                        component={TextInput}
                                        label="Phone number 2"
                                    />
                                    <Field
                                        value={conversionValues.skype}
                                        name="skype"
                                        component={TextInput}
                                        label="Skype username"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.country}
                                        name="country"
                                        component={TextInput}
                                        label="Country and time zone*"
                                    />
                                    <Field
                                        value={conversionValues.motherT}
                                        name="motherT"
                                        component={TextInput}
                                        label="Mother tongue"
                                    />
                                    <Field
                                        value={conversionValues.sex}
                                        name="sex"
                                        component={SelectInput}
                                        options={selectOptions(formParams.sex)}
                                        label="Sex"
                                    />
                                    <Field
                                        value={conversionValues.age}
                                        name="age"
                                        component={TextInput}
                                        type="number"
                                        label="Age"
                                    />
                                </Form.Group>
                            </>}
                            {step === ConversionSteps.StudentDetails && <>
                                <Button onClick={copyFromClient} type="button" style={{ marginBottom: '1rem' }}>Copy from client</Button>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.firstNameStudent}
                                        name="firstNameStudent"
                                        component={TextInput}
                                        placeholder="English please"
                                        label="First Name"
                                    />
                                    <Field
                                        value={conversionValues.lastNameStudent}
                                        name="lastNameStudent"
                                        component={TextInput}
                                        placeholder="English please"
                                        label="Last Name"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.emailStudent}
                                        name="emailStudent"
                                        component={TextInput}
                                        label="Lead Email"
                                    />
                                    <Field
                                        value={conversionValues.email2Student}
                                        name="email2Student"
                                        component={TextInput}
                                        label="Lead Email 2"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.phoneStudent}
                                        name="phoneStudent"
                                        component={TextInput}
                                        label="Phone number"
                                    />
                                    <Field
                                        value={conversionValues.phone2Student}
                                        name="phone2Student"
                                        component={TextInput}
                                        label="Phone number 2"
                                    />
                                    <Field
                                        value={conversionValues.skypeStudent}
                                        name="skypeStudent"
                                        component={TextInput}
                                        label="Skype username"
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.countryStudent}
                                        name="countryStudent"
                                        component={TextInput}
                                        label="Country and time zone"
                                    />
                                    <Field
                                        value={conversionValues.motherTStudent}
                                        name="motherTStudent"
                                        component={TextInput}
                                        label="Mother tongue"
                                    />
                                    <Field
                                        value={conversionValues.sexStudent}
                                        name="sexStudent"
                                        component={SelectInput}
                                        options={selectOptions(formParams.sex)}
                                        label="Sex"
                                    />
                                    <Field
                                        value={conversionValues.ageStudent}
                                        name="ageStudent"
                                        component={TextInput}
                                        type="number"
                                        label="Age"
                                    />
                                </Form.Group>
                            </>}
                            {step === ConversionSteps.CourseDetails && <>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.category}
                                        name="category"
                                        component={SelectInput}
                                        options={selectOptions(formParams.category)}
                                        label="Category"
                                    />
                                    <Field
                                        value={conversionValues.language}
                                        name="language"
                                        component={SelectInput}
                                        options={selectOptions(formParams.language).sort((prev, next) => prev.text.localeCompare(next.text))}
                                        label="Language"
                                    />
                                    <Field
                                        value={conversionValues.type}
                                        name="type"
                                        component={SelectInput}
                                        options={[{ text: '', value: '' }, { text: 'Group lessons', value: 'Group lessons' }, { text: 'Private lessons', value: 'Private lessons' }]}
                                        label="Type of lessons"
                                    />
                                    <Field
                                        value={conversionValues.level}
                                        name="level"
                                        component={SelectInput}
                                        options={selectOptions(formParams.level)}
                                        label="Current level"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Field
                                        value={conversionValues.quantity}
                                        name="quantity"
                                        component={TextInput}
                                        type='number'
                                        label="Number of lessons"
                                        width={3}
                                    />
                                    <Field
                                        value={conversionValues.availabilty}
                                        name="availabilty"
                                        rows={3}
                                        label="General Availability for lessons"
                                        width={5}
                                        component={TextAreaInput}
                                        placeholder="Specify if first lesson is different"
                                    />
                                    <Field
                                        value={conversionValues.focus}
                                        name="focus"
                                        rows={3}
                                        label="What should the course focus on?"
                                        width={8}
                                        component={TextAreaInput}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Field
                                        value={conversionValues.teacherLang}
                                        name="teacherLang"
                                        component={SelectInput}
                                        options={[{ text: '', value: '' }, { text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }, { text: 'Other - please specify under comments', value: 'Other - see comments' }]}
                                        label="Does the student need a teacher who speaks his mother tongue"
                                        width={6}
                                    />
                                    {values.teacherLang === 'Other - see comments' && <Field
                                        value={conversionValues.serviceComments}
                                        name="serviceComments"
                                        rows={3}
                                        label="Comments for Service team"
                                        width={10}
                                        component={TextAreaInput}
                                    />}
                                </Form.Group>
                            </>}
                            {step === ConversionSteps.PaymentDetails &&
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.price}
                                        name="price"
                                        component={TextInput}
                                        label="Price of package including VAT"
                                    />
                                    <Field
                                        value={conversionValues.currency}
                                        name="currency"
                                        component={SelectInput}
                                        options={selectOptions(formParams.currency, true)}
                                        label="Currency"
                                    />
                                    <Field
                                        value={conversionValues.method}
                                        name="method"
                                        component={SelectInput}
                                        options={[{ text: '', value: '' }, { text: 'Cheque', value: 'Cheque' }, { text: 'Bank transfer', value: 'Bank transfer' }, { text: 'Paypal', value: 'Paypal' }, { text: 'Regular credit', value: 'Regular credit' }, { text: '2 Installments', value: '2 Installments' }]}
                                        label="Payment method"
                                    />
                                    <Popup content="Israelis who live abroad aren't, unless they pay with an Israeli credit card and/or are visting abroad for a short period of time"
                                        trigger={<Field
                                            value={conversionValues.vat}
                                            name="vat"
                                            component={TextInput}
                                            options={[{ text: 'No', value: 'No' }, { text: 'Yes', value: 'Yes' }]}
                                            label="VAT applicable?"
                                        />}
                                    />
                                </Form.Group>
                            }
                            {step === ConversionSteps.InternalNotes && <>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.assignment}
                                        name="assignment"
                                        rows={3}
                                        label="Special considerations for teacher assignment-special needs"
                                        component={TextAreaInput}
                                    />
                                    <Field
                                        value={conversionValues.serviceComments}
                                        name="serviceComments"
                                        rows={3}
                                        label="Comments for Service team"
                                        component={TextAreaInput}
                                    />
                                </Form.Group>
                                <Form.Group widths={4}>
                                    <Field
                                        value={conversionValues.techComments}
                                        name="techComments"
                                        rows={3}
                                        label="Comments for the technical support team"
                                        width={8}
                                        component={TextAreaInput}
                                    />
                                    <Field
                                        value={conversionValues.tech}
                                        name="tech"
                                        component={SelectInput}
                                        options={[{ text: 'No', value: 'No' }, { text: 'Yes', value: 'Yes' }]}
                                        label="Tech test required?"
                                    />
                                    <Field
                                        value={conversionValues.techDate1}
                                        name="techDate1"
                                        component={TextInput}
                                        label="Dates for tech test 1"
                                    />
                                    <Field
                                        value={conversionValues.techDate2}
                                        name="techDate2"
                                        component={TextInput}
                                        label="Dates for tech test 2"
                                    />
                                </Form.Group>
                            </>}
                            {step === ConversionSteps.ClientNotes && <>
                                <Form.Group widths="equal">
                                    <Field
                                        value={conversionValues.rep}
                                        name="rep"
                                        component={SelectInput}
                                        options={selectOptions(formParams.rep, true)}
                                        label="Rep that sold the course"
                                        width={5}
                                        />
                                    <Field
                                        value={conversionValues.owner}
                                        name="owner"
                                        component={SelectInput}
                                        options={selectOptions(formParams.rep, true)}
                                        label="Lead Owner"
                                        width={5}
                                    />
                                    <Field
                                        value={conversionValues.leadComments}
                                        name="leadComments"
                                        rows={3}
                                        label="Comments for the lead"
                                        component={TextAreaInput}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16}>
                                        <FileUpload label="Upload Invoice - Do NOT forget ID/dealer number" accept=".pdf" maxFiles={1} onFiles={setInvoice} render={
                                            isDragActive => {
                                                const icon = <Icon name="upload" size="huge" />;
                                                const invoicePreview = () => {
                                                    if (!invoiceSrc) return null;
                                                    return <div>
                                                        <iframe src={invoiceSrc} style={{ minWidth: '100%', height: '50vh' }}/>
                                                        {/* <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: 5 }}></div> */}
                                                        <p>Click here to replace, or drag &amp; drop another file</p>
                                                    </div>;
                                                }
                                                const preview = invoicePreview();
                                                return preview
                                                    || <>
                                                        {icon}
                                                        {isDragActive
                                                            ? <p>Drop the invoice here</p>
                                                            : preview
                                                            || <p>Drag &amp; drop invoice here, or click to select a file</p>
                                                        }
                                                    </>
                                            }
                                        } />
                                    </Form.Field>
                                </Form.Group>
                            </>}
                            {step > ConversionSteps.ClientDetails && <Button onClick={() => movePrev(values)} type="button" floated="left">Back</Button>}
                            {step < ConversionSteps.ClientNotes && <Button onClick={() => moveNext(values)} disabled={invalid} type="button" floated="right">Next</Button>}
                            {step === ConversionSteps.ClientNotes && 
                                <Button
                                    floated="right"
                                    color="blue"
                                    type="submit"
                                    content="Submit"
                                    disabled={invalid}
                                />
                            }
                        </Form>
                    )}
                />
            </GridColumn>
        </Grid>
    );
}