import React from 'react'
import { observer } from 'mobx-react-lite';
import ScrollToTop from './ScrollToTop';
import Routs from './Routs';

export const MainContent = () => {

    return (
        <ScrollToTop>
            <Routs />
        </ScrollToTop>
    );
}

export default observer(MainContent);