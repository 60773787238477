import React, { useContext, useState } from 'react';
import { Button, Checkbox, Form, Header, Popup, Select, StrictDropdownProps, Table } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import agent from '../../app/api/agent';
import { LoadingComponent } from '../../app/layout/LoadingComponent';
import { ILanguage } from '../../app/models/language';
import { RootStoreContext } from '../../app/stores/rootStore';
import { TextInput } from '../../app/common/form/TextInput';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';
import { combineValidators, composeValidators, isRequired, matchesPattern } from 'revalidate';
import { displayErrors } from '../../app/utils/errors';

const validate = combineValidators({
	url: composeValidators(
		isRequired('URL'),
		matchesPattern(/https:\/\/[a-z0-9]*[^\.](?:\.[a-z0-9]+){1,}(?:\/[^\/].*)?\/?/)({message: 'Must be a valid URL'})
	)()
});

type LanguageRowProps = {
	language: ILanguage;
	crmOptions: StrictDropdownProps['options'];
	onUpdateLanguage: (language: ILanguage) => void;
};

const inlineStyle = { marginInlineStart: '1rem' };

export const LanguageRow = ({ language, crmOptions, onUpdateLanguage }: LanguageRowProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const updateLanguage = (newLanguage: ILanguage) => {
		setIsLoading(true);
		agent.Languages.update(newLanguage)
			.then(() => {
				onUpdateLanguage(newLanguage);
			})
			.catch(displayErrors)
			.finally(() => setIsLoading(false));
	}

	const setAvailability = (isAvailable?: boolean) => {
		updateLanguage({ ...language, isAvailable: isAvailable ?? false });
	};

	const { modalStore } = useContext(RootStoreContext);

	const toggleTestInvitation = (shouldSend?: boolean) => {
		const handleSubmit = ({ url }: { url: string; }) => {
			modalStore.closeModal();
			updateLanguage({
				...language,
				sendsTestInvitation: shouldSend ?? language.sendsTestInvitation,
				testInvitationUrl: url ?? language.testInvitationUrl,
			});
		};

		modalStore.openModal(
			<FinalForm
				onSubmit={handleSubmit}
				validate={shouldSend ? validate : undefined}
				render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
					<Form onSubmit={handleSubmit} error>
						<Header as='h2' content={shouldSend ? 'Enter URL' : 'Are you sure?'} color='blue' textAlign='center' />
						{shouldSend && (
							<>
								<Field name='url' component={TextInput} initialValue={language.testInvitationUrl} />
								{submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text='Invalid URL' />}
							</>
						)}
						<Button color='youtube' content='Cancel' loading={submitting} onClick={modalStore.closeModal} />
						<Button color='instagram' content='Confirm' loading={submitting} disabled={shouldSend && ((invalid && !dirtySinceLastSubmit) || (pristine && language.sendsTestInvitation))} />
					</Form>
				)}
			/>
		);
	};

	const handleCrmChange = (value: StrictDropdownProps['value']) => {
		updateLanguage({ ...language, crmCode: Number(value) });
	};

	const editHebrewName = () => {
		const handleSubmit = ({ nameHebrew }: { nameHebrew?: string; }) => {
			modalStore.closeModal();
			updateLanguage({ ...language, nameHebrew });
		};

		modalStore.openModal(
			<FinalForm
				onSubmit={handleSubmit}
				validate={combineValidators({ nameHebrew: isRequired('Hebrew Name') })}
				render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
					<Form onSubmit={handleSubmit} error>
						<Header as='h2' content={`Enter Hebrew Name for ${language.name}`} color='blue' textAlign='center' />
						<Field name='nameHebrew' component={TextInput} initialValue={language.nameHebrew} />
						{submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text='Invalid Hebrew Name' />}
						<Button color='youtube' content='Cancel' loading={submitting} onClick={modalStore.closeModal} />
						<Button color='instagram' content='Confirm' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} />
					</Form>
				)}
			/>
		);
	};

	const editTestUrl = () => {
		const handleSubmit = ({ url }: { url: string; }) => {
			modalStore.closeModal();
			updateLanguage({
				...language,
				testInvitationUrl: url ?? language.testInvitationUrl,
			});
		};

		modalStore.openModal(
			<FinalForm
				onSubmit={handleSubmit}
				validate={validate}
				render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
					<Form onSubmit={handleSubmit} error>
						<Header as='h2' content="Enter URL" color='blue' textAlign='center' />
						<Field name='url' component={TextInput} initialValue={language.testInvitationUrl} />
						{submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text='Invalid URL' />}
						<Button color='youtube' content='Cancel' loading={submitting} onClick={modalStore.closeModal} />
						<Button color='instagram' content='Confirm' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} />
					</Form>
				)}
			/>
		);
	};

	return (
		<Table.Row>
			{isLoading && <LoadingComponent />}
			<Table.Cell>{language.name}</Table.Cell>
			<Table.Cell>
				<Checkbox toggle defaultChecked={language.isAvailable} onChange={(_, data) => setAvailability(data.checked)} />
			</Table.Cell>
			<Table.Cell>
				<Checkbox toggle checked={language.sendsTestInvitation} onChange={(_, data) => toggleTestInvitation(data.checked)} />
				{language.testInvitationUrl && (
					<>
						<a href={language.testInvitationUrl} target="_blank" style={inlineStyle}>
							{language.testInvitationUrl && decodeURI(language.testInvitationUrl)}
						</a>
						<Popup content="Edit Invitation URL" trigger={
							<Button circular icon="edit" color="orange" style={inlineStyle} inverted onClick={editTestUrl} />
						} />
					</>
				)}
			</Table.Cell>
			<Table.Cell>
				<Select options={crmOptions ?? []} value={language.crmCode} onChange={(_, data) => handleCrmChange(data.value)} />
			</Table.Cell>
			<Table.Cell>
				{language.nameHebrew}
				<Popup content={`${language.nameHebrew ? 'Edit' : 'Add'} Hebrew Name`} trigger={
					<Button circular icon={language.nameHebrew ? 'edit' : 'add'} color={language.nameHebrew ? 'orange' : 'green'} inverted style={inlineStyle} onClick={editHebrewName} />
				} />
			</Table.Cell>
		</Table.Row>
	);
};