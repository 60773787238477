import { ILanguage } from "./language";
import { ITeacherBasic } from "./teacher";

export enum CourseStatus {
    Pending = 'Pending',
    Available = 'Available',
    InProgress = 'InProgress',
    Finished = 'Finished',
    GoneCol = 'GoneCold'
}

export interface ICourse {
    language: ILanguage;
    teachers: ITeacherBasic[];
    id: number;
    name: string;
    courseStatus: CourseStatus;
}