import { observable, action, runInAction } from "mobx";
import { IReleasedSoldierVoucherViewModel } from "../models/released-soldier-voucher";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { history } from '../..';
import { RootStore } from "./rootStore";

export default class VoucherStore {
  rootStore: RootStore;
  
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable voucherRegistry = new Map<number, IReleasedSoldierVoucherViewModel>();
  @observable loadingSpinner = false;
  @observable voucher: IReleasedSoldierVoucherViewModel | null = null;
  
  public submitVoucherForPaymentAsync = () => {
    this.runWithSpinnerAsync(async () => {
      this.voucher && await agent.Vouchers.submitForPaymentAsync(this.voucher.id)
          .then(() => history.push('/vouchers/' + this.voucher?.id))
          .catch(e => console.error("Unable to submit for payment", e));
    });
  }

  private fetchVoucherListAsync = async () => {
    try {
      return await agent.Vouchers.list();
    } catch (error) {
      console.log(error);
      return [];
      //throw new Error("Unable to retrieve voucher list from server.\n" + error);
    }
  };

  public loadVouchers = () => {
    this.runWithSpinnerAsync(async () => {
      const vouchers = await this.fetchVoucherListAsync();
      this.fillVouchers(vouchers);
    });
  };

  public setSelectedVoucherAsync = async (id: number) => {
    const voucher = this.voucherRegistry.get(id) || await this.fetchVoucherAsync(id) || null;
    runInAction(() => {
      voucher.voucherDate instanceof Date && (voucher.voucherDate = new Date(voucher.voucherDate));
      voucher && this.setVoucher(voucher);
      this.voucher = voucher;
    });
  };

  @action loadIndividualVoucherAsync = async (id: number) => {
    const voucher = await this.fetchVoucherAsync(id);
    if (!voucher) {
      toast.error("Voucher not found!");
      history.push('/vouchers');
    } else {
      runInAction(() => {
        this.voucher = this.convertToDate(voucher);
      });
    }
    return voucher;
  }

  private fetchVoucherAsync = async (id: number): Promise<IReleasedSoldierVoucherViewModel> => {
    return await this.runWithSpinnerAsync(async () => {
      try {
        return await agent.Vouchers.details(id);
      } catch (error) {
        console.log("Unable to retrieve voucher from server.\n" + error);
      }
    });
  }

  @action deleteVoucherAsync = async (id: number) => {
    this.runWithSpinnerAsync(async () => {
      try {
        await agent.Vouchers.delete(id);
      } catch (error) {
        throw new Error("Unable to delete voucher from server.\n" + error);
      }
      runInAction(() => {
        this.voucherRegistry.delete(id);
        this.voucher = null;
      });
    });
  };

  @action submitVoucherAsync = async (voucher: IReleasedSoldierVoucherViewModel) => {
    let id;
    await this.runWithSpinnerAsync(async () => {
      if (voucher.id) {
        await this.updateVoucherAsync(voucher);
      } else {
        id = await this.createVoucherAsync(voucher);
      }
    });
    history.push("/vouchers/" + (id ?? ''));
    return this.voucher && this.voucher.id;
  };

  private runWithSpinnerAsync = async (callBack: Function) => {
    this.setSpinner(true);
    const result = await callBack();
    this.setSpinner(false);
    return result;
  };

  @action private setSpinner = (show: boolean) => (this.loadingSpinner = show);

  public readonly getVoucherArray = () =>
    Array.from(this.voucherRegistry.values());

  private readonly getVoucherIds = () => this.getVoucherArray().map(v => v.id);

  private fillVouchers = (vouchers: IReleasedSoldierVoucherViewModel[]) => {
    const ids = this.getVoucherIds();
    vouchers.forEach(voucher => {
      if (!ids.includes(voucher.id)) {
        this.setVoucher(voucher);
      }
    });
  };

  private convertToDate = (voucher: IReleasedSoldierVoucherViewModel) => {
    if (typeof voucher.voucherDate === 'string') voucher.voucherDate = new Date(voucher.voucherDate);
    return voucher;
  }

  @action private setVoucher = (voucher: IReleasedSoldierVoucherViewModel) => {
    if (typeof voucher.voucherDate === 'string') voucher.voucherDate = new Date(voucher.voucherDate);
    voucher.id && this.voucherRegistry.set(voucher.id, voucher);
  }

  private async createVoucherAsync(voucher: IReleasedSoldierVoucherViewModel) {
    try {
      await agent.Vouchers.create(voucher);
    } catch (error) {
      toast.error("Unable to create voucher in server.");
      console.log(error.response);
      return;
    }
    const vouchers = await this.fetchVoucherListAsync();
    const ids = this.getVoucherIds();
    this.fillVouchers(vouchers);
    const id = vouchers.filter(v => !ids.includes(v.id))[0].id ?? NaN;
    this.setSelectedVoucherAsync(id);
    return id;
  }

  @action private updateVoucherAsync = async (voucher: IReleasedSoldierVoucherViewModel) => {
    try {
      await agent.Vouchers.update(voucher.id, voucher);
    } catch (error) {
      throw new Error("Unable to update voucher in server.\n" + error);
    }
    this.setVoucher(voucher);
    voucher.id && this.setSelectedVoucherAsync(voucher.id);
  }
}