import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

interface IProps {
    label?: string;
    accept?: string | string[];
    maxFiles?: number;
    onFiles: (files: File[]) => void;
    render: (isDragActive: boolean) => JSX.Element;
};

const FileUpload = ({ accept, maxFiles = 0, onFiles, render, label }: IProps) => {
    const dzStyles = {
        border: 'dashed 3px',
        borderColor: '#eee',
        borderRadius: '3px',
        textAlign: 'center' as 'center',
        cursor: 'pointer'
    };

    const dzActive = {
        ...dzStyles,
        borderColor: 'green',
        color: 'green',
    };

    const onDrop = useCallback(onFiles, [onFiles]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxFiles, multiple: maxFiles > 1 || maxFiles === 0 });
     
    return (<>
        <label>{label}</label>
        <div {...getRootProps() } style={isDragActive ? dzActive : dzStyles} >
            <input { ...getInputProps() } />
            {render(isDragActive)}
        </div>
    </>);
}

export default FileUpload;