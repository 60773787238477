import React, { useContext, Fragment } from 'react'
import { Container, Header, Button } from 'semantic-ui-react'
import { useMetaTitle } from '../../app/layout/useMetaTitle';
import { RootStoreContext } from '../../app/stores/rootStore'
import { LoginForm } from '../user/LoginForm';

export const HomePage = () => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;

  useMetaTitle('Dashboard');
  return (
    <Container>
      {isLoggedIn && user ? (
        <Fragment>
          <Header as='h2' content={user.displayName + ', Welcome to LingoLearn Management system'} />
          {/* <Button as={Link} to='/vouchers' size='huge' >Go to Vouchers</Button> */}Nothing here ATM...
        </Fragment>
      ) : (
        <Fragment>
          <Header as='h2' content={'Welcome to LingoLearn Management system'} />
          <Button onClick={() => openModal(<LoginForm/>)} size='huge' >Login</Button>
          {/* <Button onClick={() => openModal(<RegisterForm/>)} size='huge' >Register</Button> */}
        </Fragment>
      )}
    </Container>
  )
}
