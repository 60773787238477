import React, { Fragment, useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { SideBar } from "../../features/nav/SideBar";
import HeaderBar from "../../features/nav/HeaderBar";
import { MainContent } from "./MainContent";
import { ToastContainer } from 'react-toastify';
import { RootStoreContext } from "../stores/rootStore";
import { LoadingComponent } from "./LoadingComponent";
import { observer } from "mobx-react-lite";
import ModalContainer from "../common/modals/ModalContainer";

const App = () => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token])

  if (!appLoaded) return <LoadingComponent />

  return (
    <Fragment>
      <ModalContainer/>
      <ToastContainer position='bottom-right' autoClose={12345}/>
      <HeaderBar />
      <Grid>
        <Grid.Column width={3}>
          <SideBar />
        </Grid.Column>
        <Grid.Column width={13}>
          <MainContent></MainContent>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default observer(App);
