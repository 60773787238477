import React, { useState } from 'react'
import { Button, Checkbox, CheckboxProps, Container, Grid, Label, List} from 'semantic-ui-react';
import agent from '../../app/api/agent';
import { ILanguage } from '../../app/models/language';
import { ISelectableTeacher } from './SelectTeachersForm';

interface IProps {
    teacher: ISelectableTeacher;
    language: ILanguage;
    onToggle: () => void;
}

export const TeacherSelector = React.memo<IProps>(({ teacher, language, onToggle }) => {
    
    const [crossedOff, setCrossedOff] = useState(false);
    const [teachingLevels, setTeachingLevels] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const toggleChange = (_: any, c: CheckboxProps) => {
        const crossOff = !c.checked;
        teacher.deSelected = crossOff;
        setCrossedOff(crossOff);
        onToggle();
    }

    const handleGetInfo = () => {
        setLoading(true);
        agent.Teachers.getTeacher(teacher.id)
        .then(t => setTeachingLevels(t.languages.filter(lang => lang.id === language.id).map(lang => lang.levels.map(lev => lev.name)).flat()))
        .catch(e => {
            alert('ERROR!');
            console.log(e);
        })
        .finally(() => setLoading(false));
    }
    
    return (
        <Container>
        <Grid>
            <Grid.Column width="1">

        <Checkbox toggle defaultChecked={!crossedOff} onChange={toggleChange}></Checkbox>
            </Grid.Column>
            <Grid.Column width="15">

            <List.Header as="h4" className={crossedOff ? 'crossed-off' : ''}>
                {`${teacher.firstName} ${teacher.lastName}`}
            </List.Header>
                <List.Description>
                    {teachingLevels.length
                    ? (<><Label basic>Levels of Teaching: </Label>{teachingLevels.join(', ')}</>)
                    : <Button onClick={handleGetInfo} disabled={loading}>Get More Info</Button>}
                </List.Description>
            </Grid.Column>
        </Grid>
        </Container>
    )
});